'use strict';

angular.module('cpformplastApp')
    .controller('DashboardController', function($scope, DataManager, Notification, MaterialCalendarData) {

    // Stat cards
    $scope.ordersElements = [];
    $scope.deliveriesElements = [];
    $scope.finishedProductsElements = [];
    $scope.rawMaterialsElements = [];

    $scope.$on('elementClick.directive', function(angularEvent, event){return d.y;});

    // Table structure
    $scope.rawMaterialsProductionDeliveriesTableStructure = [{
      'title':'Quantité (lbs)',
      'value':'quantity',
      'filter':'alpha'
    },{
      'title':'Matériel',
      'value':'dimcat',
      'filter':'alpha'
    },{
      'title':'#Job',
      'value':'job',
      'filter':'alpha'
    },{
      'title':'Client',
      'value':'client',
      'filter':'alpha'
    }];
    $scope.finishedProductsProductionDeliveriesTableStructure = [{
      'title':'Unités',
      'value':'quantity',
      'filter':'alpha'
    },{
      'title':'Item',
      'value':'item',
      'filter':'alpha'
    },{
      'title':'#Job',
      'value':'job',
      'filter':'alpha'
    },{
      'title':'Client',
      'value':'client',
      'filter':'alpha'
    }];

    // Line chart
    $scope.lineChartMargin = 20;
    $scope.lineChartData = [];

    $scope.init = function() {

      DataManager.fetchOrdersCount().then(data => {
        for (var i=0 ; i<data.length ; i++) {
          var date = new Date(data[i].date);
          data[i].title = ('0'+date.getUTCDate()).slice(-2) + '/' + ('0'+(date.getUTCMonth()+1)).slice(-2);
          data[i].value = data[i].amount;
        }
        $scope.ordersElements = data;
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un probème est survenu lors du chargement');
      });

      DataManager.fetchDeliveriesCount().then((data) => {
        for (var i=0 ; i<data.length ; i++) {
          var date = new Date(data[i].date);
          data[i].title = ('0'+date.getUTCDate()).slice(-2) + '/' + ('0'+(date.getUTCMonth()+1)).slice(-2);
          data[i].value = data[i].amount;
        }
        $scope.deliveriesElements = data;
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un probème est survenu lors du chargement');
      });

      // // Pie chart
      $scope.pieSize = 160;
      $scope.piePortions = [{
        'label': 'Vide',
        'value': 1
      },{
        'label': 'Produits finis',
        'value': 1
      },{
        'label': 'Matière Première',
        'value': 1
      }];

      DataManager.fetchInventoryDistribution().then((data) => {
        $scope.piePortions[0].value = Math.round((100/600) * (600 - data['finished-product'] - data['raw-material']));
        $scope.piePortions[1].value = Math.round((100/600) * data['finished-product']);
        $scope.piePortions[2].value = Math.round((100/600) * data['raw-material']);
        $scope.data = [
            {
                key: "Vide",
                y: $scope.piePortions[0].value,
                color: '#DEDEDE'
            },
            {
                key: "Produits finis",
                y: $scope.piePortions[1].value,
                color: '#00bcd4'
            },
            {
                key: "Matière Première",
                y: $scope.piePortions[2].value,
                color: '#8bc34a'
            }
        ];
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un probème est survenu lors du chargement des jobs');
      });

      // // Options du pieChart
      $scope.options = {
          chart: {
              type: 'pieChart',
              height: 306,
              width: 190,
              margin: {
                        "top": 0,
                        "right": 0,
                        "bottom": 0,
                        "left": 0
                      },
              lines: {
                dispatch: {
                  elementClick: function(e){ return d.y; },
                }
              },
              x: function(d){return d.key;},
              y: function(d){return d.y;},
              showLabels: true,
              labelType: 'percent',
              duration: 500,
              labelThreshold: 0.01,
              labelSunbeamLayout: false,
              tooltip: {
                enabled: true,
                valueFormatter: function (d, i) {
                                    return (d +' %');
                                }
              },
              legend: {
                  margin: {
                      top: 5,
                      right: 0,
                      bottom: 5,
                      left: 8
                  },
                  rightAlign: false
              }
          }
      };

      DataManager.fetchCompletedFinishedProductsCount(30).then((data) => {
        for (var i=0 ; i<data.length ; i++) {
          var date = new Date(data[i].date);
          data[i].title = ('0'+date.getUTCDate()).slice(-2) + '/' + ('0'+(date.getUTCMonth()+1)).slice(-2);
          data[i].value = data[i].amount;
        }
        $scope.lineChartData = data;
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un probème est survenu lors du chargement des statistiques quotidiennes');
      });

      // // Stats pour la vue hebdomadaire
      DataManager.fetchCompletedFinishedProductsCountWeek(28).then((data) => {

        for (var i=0 ; i<data.length ; i++) {
          var date = new Date(data[i].date);
          data[i].title = ('0'+date.getUTCDate()).slice(-2) + '/' + ('0'+(date.getUTCMonth()+1)).slice(-2);
          data[i].value = data[i].amount;
        }
        $scope.lineChartDataWeek = data;
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un probème est survenu lors du chargement des statistiques hebdomadaires');
      });

      DataManager.fetchGroupedRawMaterials().then((data) => {
        for (var i=0 ; i< data.length ; i++) {
          if (data[i].job){
            $scope.rawMaterialsElements.push({
              'dimcat': data[i].category.dimension + ' ' + data[i].category.name,
              'job': data[i].job.number,
              'client': data[i].job.item.client.name.toUpperCase(),
              'quantity': Number(data[i].quantity).toLocaleString(),
              'quantityInSkid': data[i].quantityInSkid
            });
          } else {
            $scope.rawMaterialsElements.push({
              'dimcat': data[i].category.dimension + 'x' + data[i].category.name,
              'quantity': Number(data[i].quantity).toLocaleString(),
              'quantityInSkid': data[i].quantityInSkid
            });
          }
        }
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu lors du chargement des livraisons');
      });

      DataManager.fetchGroupedFinishedProducts().then((data) => {
        for (var i=0 ; i< data.length ; i++) {
          $scope.finishedProductsElements.push({
            'job': data[i].job.number,
            'client': data[i].job.item.client.name.toUpperCase(),
            'item': data[i].job.item.name,
            'po': data[i].job.po,
            'code': data[i].job.item.code,
            'quantity': Number(data[i].quantity).toLocaleString(),
            'quantityInSkid': data[i].quantityInSkid
          });
        }
      })
      .catch(err => {
        console.log(err);
        Notification.error('Un problème est survenu lors du chargement des livraisons');
      });

      DataManager.fetchDates().then((data) => {
        for(var i=0 ; i<data.length ; i++) {
          var htmlString = '';
          for (var j=0 ; j<data[i].content.length ; j++) {
            htmlString += "<p class='calendar-content'>" + data[i].content[j] + '</p>';
          }
          MaterialCalendarData.setDayContent(new Date(data[i].date), htmlString);
        }

      })
      .catch(err => {
        console.log(err);
        Notification.error('Un probème est survenu lors du chargement des dates');
      });

    };

    $scope.init();
});
